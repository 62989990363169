import React from 'react'

function Student() {
  return (
    <div>
        
        
    </div>
  )
}

export default Student