import React from 'react'
import { Link } from 'react-router-dom'

function Feedback() {
  return (
<div className="container">
      <br />
      <div className="row justify-content-center">
        <div className="col-xs-6 text-center">
          <h1><i class="bi bi-chat-right-text-fill"></i> <strong>AICTE Feedback</strong></h1>
        </div>
      </div>
      <div className="row justify-content-center" style={{ marginTop: '5%' }}>
        <div className="alert alert-warning col-xs-5 mx-3" style={{ width: '45%', background: '#084c9d' }}>
          <Link to="https://www.aicte-india.org/feedback/students.php" target='_blank' style={{ textDecoration: 'none', color: '#FFFF' }}>
            <h1 className="text-center">
            <i class="bi bi-person-fill"></i> Students
            </h1>
          </Link>
        </div>
        <div className="alert alert-warning col-xs-5 mx-3" style={{ width: '40%', background: '#084c9d' }}>
          <Link to="https://www.aicte-india.org/feedback/faculty.php" target='_blank' style={{ textDecoration: 'none', color: '#FFFF' }}>
            <h1 className="text-center">
            <i class="bi bi-bank"></i> Faculty
            </h1>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Feedback