import React from 'react';


export default function Footer() {
  return (
    <div> <footer id="footer">
    <div className="footer-top">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-6">
            <div className="footer">
            <div className="row">
            <div className="col-lg-4 col-md-6">
            <img src="assets/img/SCPlogo.png" alt="logo-img" style={{ height: '', width: '40%', }} />
            <br />
            <br />
              <p>
                Address:<br />
                Panukuvalasa(V),Pachipenta (M),Parvathipuram Manyam(Dist),<br></br>  Andhra Pradesh 535592 <br></br><strong>POLYCET CODE: SCPC</strong> <br /><br />
                </p>
              
              </div>
              <div className="col-lg-4 col-md-6">
              <p>
                <strong>Phone:</strong> <br/>9959209807<br />
                8885553331 <br />
                8142030859 <br />
                9440857428 <br />
                                        <br />
                                      
                <strong>Email:</strong>    Scpcslr@gmail.com<br />
              </p>
              <div className="social-links mt-3">
                <a href="#" className="twitter"><i className="bx bxl-twitter" /></a>
                <a href="#" className="facebook"><i className="bx bxl-facebook" /></a>
                <a href="#" className="instagram"><i className="bx bxl-instagram" /></a>
                <a href="#" className="google-plus"><i className="bx bxl-skype" /></a>
                <a href="#" className="linkedin"><i className="bx bxl-linkedin" /></a>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 footer-links">
            <h4>Useful Links</h4>
            <ul>
              <li><i className="bx bx-chevron-right" /> <a href="/">Home</a></li>
              <li><i className="bx bx-chevron-right" /> <a href="/about">About us</a></li>
              <li><i className="bx bx-chevron-right" /> <a href="/contact">Contact</a></li>
              

            </ul>
          </div>

          </div>
          </div>
          </div>
          
          <div className="col-lg-2 col-md-6 footer-links">
            
            <h4>Our Courses</h4>
            <ul>
            <li><i className="bx bx-chevron-right" /> <a href="#">Mechanical </a></li>
              <li><i className="bx bx-chevron-right" /> <a href="#">Civil </a></li>
              <li><i className="bx bx-chevron-right" /> <a href="#">EEE</a></li>
              <li><i className="bx bx-chevron-right" /> <a href="#">ECE</a></li>
            
              
            </ul>
          </div>
          
          
        </div>
      </div>
    </div>

  </footer>{/* End Footer */}</div>
  )
}














 
          {/* ======= Cta Section ======= */}
          {/* <section id="cta" className="cta">
            <div className="container" data-aos="zoom-in">
              <div className="text-center">
                <h3>Call To Action</h3>
                <p> Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <a className="cta-btn" href="#">Call To Action</a>
              </div>
            </div>
          </section>End Cta Section */}
 
          {/* ======= Team Section ======= */}
          {/* <section id="team" className="team section-bg">
            <div className="container" data-aos="fade-up">
              <div className="section-title">
                <h2>Team</h2>
                <p>Check our Team</p>
              </div>
              <div className="row">
                <div className="col-xl-3 col-lg-4 col-md-6">
                  <div className="member" data-aos="zoom-in" data-aos-delay={100}>
                    <img src="assets/img/team/team-1.jpg" className="img-fluid" alt="" />
                    <div className="member-info">
                      <div className="member-info-content">
                        <h4>Walter White</h4>
                        <span>Chief Executive Officer</span>
                      </div>
                      <div className="social">
                        <a href><i className="bi bi-twitter" /></a>
                        <a href><i className="bi bi-facebook" /></a>
                        <a href><i className="bi bi-instagram" /></a>
                        <a href><i className="bi bi-linkedin" /></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6" data-wow-delay="0.1s">
                  <div className="member" data-aos="zoom-in" data-aos-delay={200}>
                    <img src="assets/img/team/team-2.jpg" className="img-fluid" alt="" />
                    <div className="member-info">
                      <div className="member-info-content">
                        <h4>Sarah Jhonson</h4>
                        <span>Product Manager</span>
                      </div>
                      <div className="social">
                        <a href><i className="bi bi-twitter" /></a>
                        <a href><i className="bi bi-facebook" /></a>
                        <a href><i className="bi bi-instagram" /></a>
                        <a href><i className="bi bi-linkedin" /></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6" data-wow-delay="0.2s">
                  <div className="member" data-aos="zoom-in" data-aos-delay={300}>
                    <img src="assets/img/team/team-3.jpg" className="img-fluid" alt="" />
                    <div className="member-info">
                      <div className="member-info-content">
                        <h4>William Anderson</h4>
                        <span>CTO</span>
                      </div>
                      <div className="social">
                        <a href><i className="bi bi-twitter" /></a>
                        <a href><i className="bi bi-facebook" /></a>
                        <a href><i className="bi bi-instagram" /></a>
                        <a href><i className="bi bi-linkedin" /></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6" data-wow-delay="0.3s">
                  <div className="member" data-aos="zoom-in" data-aos-delay={400}>
                    <img src="assets/img/team/team-4.jpg" className="img-fluid" alt="" />
                    <div className="member-info">
                      <div className="member-info-content">
                        <h4>Amanda Jepson</h4>
                        <span>Accountant</span>
                      </div>
                      <div className="social">
                        <a href><i className="bi bi-twitter" /></a>
                        <a href><i className="bi bi-facebook" /></a>
                        <a href><i className="bi bi-instagram" /></a>
                        <a href><i className="bi bi-linkedin" /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>End Team Section */}



      