import React from 'react'

export default function Headerlogo() {
  return (
    <div className="mid-nav pt-2 pb-2 bg-mid-nav HeaderLOGO">
  <div className="container">
    <div className="row">
      <div className="col-sm-12 col-md-12">
        <div className="view-content">
          <div className="logo logo-title">
            <a href="/" style={{ fontSize: '14px', display: 'flex', alignItems: 'center' }}>
              <img src="assets/img/logo.png" alt="logo-img" style={{ height: '', width: '100%' }} />
              {/* <h1 style={{ marginLeft: '50px' }}>Sri RamaKrishna ITI College </h1> */}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

   
  )
}
