import React,{ useEffect }  from 'react'
import Courses from '../Components/Courses';

function CoursesPage() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div>
        <Courses />
    </div>
  )
}

export default CoursesPage