import React from 'react'

export default function Portfolio() {
  return (
    <div>      <section id="portfolio" className="portfolio">
    <div className="container" data-aos="fade-up">
    <div className="section-title">
        <h2>Sri Chaitanya Polytechnic College</h2>
        <p>Our Recruites</p>
      </div>
 
      <div className="row portfolio-container" data-aos="fade-up" data-aos-delay={200}>
        <div className="col-lg-4 col-md-6 portfolio-item filter-app text-center">
          <img src="assets/img/portfolio/amararaja.jpeg" className="img-fluid" width="50%" alt="" />
         
        </div>
        <div className="col-lg-4 col-md-6 portfolio-item filter-web text-center">
          <img src="assets/img/portfolio/LT.png" className="img-fluid" width="50%" alt="" />
         
        </div>
        <div className="col-lg-4 col-md-6 portfolio-item filter-app text-center">
          <img src="assets/img/portfolio/jayabheri.png" className="img-fluid" width="50%" alt="" />
          
        </div>
        <div className="col-lg-4 col-md-6 portfolio-item filter-card text-center">
          <img src="assets/img/portfolio/Dr.reddy.png" className="img-fluid" width="50%" alt="" />
        
        </div>
        <div className="col-lg-4 col-md-6 portfolio-item filter-web text-center">
          <img src="assets/img/portfolio/GreenTech.png" className="img-fluid" width="40%" alt="" />
          
        </div>
        <div className="col-lg-4 col-md-6 portfolio-item filter-app text-center">
          <img src="assets/img/portfolio/AsianPaints.png" className="img-fluid" width="30%" alt="" />
          
        </div>
        <div className="col-lg-4 col-md-6 portfolio-item filter-card text-center">
          <img src="assets/img/portfolio/Aurobindo.png" className="img-fluid" width="50%" alt="" />
        
        </div>
        {/* <div className="col-lg-4 col-md-6 portfolio-item filter-card">
          <img src="assets/img/portfolio/portfolio-8.jpg" className="img-fluid" alt="" />
      
        </div> */}
        {/* <div className="col-lg-4 col-md-6 portfolio-item filter-web">
          <img src="assets/img/portfolio/portfolio-9.jpg" className="img-fluid" alt="" />
          <div className="portfolio-info">
            <h4>Web 3</h4>
            <p>Web</p>
            <a href="assets/img/portfolio/portfolio-9.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox preview-link" title="Web 3"><i className="bx bx-plus" /></a>
            <a href="portfolio-details.html" className="details-link" title="More Details"><i className="bx bx-link" /></a>
          </div>
        </div> */}
      </div>
    </div>
  </section></div>
  )
}
