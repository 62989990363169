import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './Components/Header';
import HomePage from './Pages/HomePage';
import AboutPage from './Pages/AboutPage';
import PortfolioPage from './Pages/PortfolioPage';
import ContactPage from './Pages/ContactPage';
import Headerlogo from './Components/Headerlogo';
import Topbar from './Components/Topbar';
import Footer from './Components/Footer';
import ElectricianPage from './Pages/ElectricianPage';
import FitterPage from './Pages/FitterPage';
import WelderPage from './Pages/WelderPage';
import FacilitiesaPage from './Pages/FacilitiesaPage';
import CoursesPage from './Pages/CoursesPage';
import FeedbackPage from './Pages/FeedbackPage';


export default function App() {
  return (
    <Router>
      <Topbar />
      <Headerlogo />
      <Header />
      <Routes>
      <Route exact path="/" element={<HomePage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/Facilities" element={<FacilitiesaPage />} />
      <Route path="/Recruites" element={<PortfolioPage />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path='/Courses' element={<CoursesPage />} />
      <Route path='/Feedback' element={<FeedbackPage />} />

      
      </Routes>
      <Footer />
      
    </Router>
  );
}




// import React, { useState } from 'react';
// import { NavLink } from 'react-router-dom';

// export default function Header() {
//   const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

//   const handleMobileMenuToggle = () => {
//     setMobileMenuOpen(!isMobileMenuOpen);
//   };

//   const handleNavLinkClick = () => {
//     if (isMobileMenuOpen) {
//       setMobileMenuOpen(false);
//     }
    
//   };
//   return (
//     <div>
//       <header id="header" className="fixed-top d-flex align-items-center">
//         <div className="container d-flex align-items-center">
//           <div className="logo me-auto">
//             <h1>
//               <NavLink to="/">Lumia</NavLink>
//             </h1>
//           </div>
//           <div
//             id="navbar"
//             className={`navbar order-last order-lg-0 ${isMobileMenuOpen ? 'navbar-mobile' : ''}`}
//           >
            
//           <ul>
//             <li>
//               <NavLink
//                 exact
//                 to="/"
//                 className="nav-link scrollto active"
//                 onClick={handleNavLinkClick}
//               >
//                 Home
//               </NavLink>
//             </li>
//             <li>
//               <NavLink
//                 to="/about"
//                 className="nav-link scrollto"
//                 onClick={handleNavLinkClick}
//               >
//                 About
//               </NavLink>
//             </li>
//             <li className={`dropdown ${isMobileMenuOpen ? 'active' : ''}`}>
//             <a href="/" className="dropdown-toggle" onClick={handleNavLinkClick}>
//             <span>Courses</span> 
//             {/* <i className="bi bi-chevron-down" /> */}
//           </a>
//           <ul>
//             <li>
//               <NavLink
//                 to="/dropdown1"
//                 className="nav-link scrollto"
//                 onClick={handleNavLinkClick}
//               >
//                 Drop Down 1
//               </NavLink>
//             </li>
//             <li>
//               <NavLink
//                 to="/dropdown2"
//                 className="nav-link scrollto"
//                 onClick={handleNavLinkClick}
//               >
//                 Drop Down 2
//               </NavLink>
//             </li>
//             <li>
//               <NavLink
//                 to="/dropdown3"
//                 className="nav-link scrollto"
//                 onClick={handleNavLinkClick}
//               >
//                 Drop Down 3
//               </NavLink>
//             </li>
//             <li>
//               <NavLink
//                 to="/dropdown4"
//                 className="nav-link scrollto"
//                 onClick={handleNavLinkClick}
//               >
//                 Drop Down 4
//               </NavLink>
//             </li>
//           </ul>
//         </li>

//             <li>
//               <NavLink
//                 to="/services"
//                 className="nav-link scrollto"
//                 onClick={handleNavLinkClick}
//               >
//                 Admissions
//               </NavLink>
//             </li>
//             <li>
//               <NavLink
//                 to="/portfolio"
//                 className="nav-link scrollto"
//                 onClick={handleNavLinkClick}
//               >
//                 Our Recruiters
//               </NavLink>
//             </li>
//             <li>
//               <NavLink
//                 to="/contact"
//                 className="nav-link scrollto"
//                 onClick={handleNavLinkClick}
//               >
//                 Contact
//               </NavLink>
//             </li>
//             <li>
//               <NavLink
//                 to="/about"
//                 className="scrollto"
//                 onClick={handleNavLinkClick}
//               >
//                 Enquire Now
//               </NavLink>
//             </li>
//           </ul>
//             <i className={`bi ${isMobileMenuOpen ? 'bi-x' : 'bi-list'} mobile-nav-toggle`} onClick={handleMobileMenuToggle} />
//           </div>
          
//         </div>
//       </header>
//     </div>
//   );
// }

