import React from 'react';

export default function AboutUs() {
  return (
    <div>
      <section id="portfolio" className="portfolio">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Sri Chaitanya Polytechnic College</h2>
            <p>Governor Body</p>
          </div>
          <div className='row'>
            <div className='col-lg-12 text-center'>
              <img src='assets/img/Principal.jpg' alt="Principal" width={100} className='mb-2'/>
              
              <p><strong>Principal: G.Tharun </strong></p>
            </div>

          </div>
          <div className="mb-30 clearfix">
            <div className="table-responsive">
              <table className="content-table" border="0" width="100%" cellSpacing="1" cellPadding="10">
                <thead>
                  <tr>
                    <th className="in_gride"><strong>S.No.</strong></th>
                    <th className="in_gride"><strong>Name</strong></th>
                    <th className="in_gride"><strong>Organization Role</strong></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>G.Sakuntala</td>
                    <td>Correspondent</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>G.kannabanu</td>
                    <td>President</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>G.Tharun</td>
                    <td>Principal</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>G.Thanamai</td>
                    <td>Vice President</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>B.Rajasekhar</td>
                    <td>Executive Officer</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
