import React,{ useEffect }  from 'react'
import Portfolio from '../Components/Portfolio'

export default function PortfolioPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
        <Portfolio />
    </div>
  )
}
