import React,{ useEffect }  from 'react'
import Contact from '../Components/Contact'

export default function ContactPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
        <Contact />
    </div>
  )
}
