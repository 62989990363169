import React,{ useEffect }  from 'react'
import About from '../Components/About'
import AboutUs from '../Components/AboutUs';
import EOA from '../Components/EOA';


export default function AboutPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
        <About />
        <AboutUs />
        <EOA />
    
    </div>
  )
}
