import React from 'react'
import Feedback from '../Components/Feedback'
import Student from '../Components/Student'

function FeedbackPage() {
  return (
    <div>
        <Feedback />
        <Student />
    </div>
  )
}

export default FeedbackPage