import React from 'react'

export default function Courses() {
  return (
    <div>   {/* ======= Services Section ======= */}
    <section id="services" className="services">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Sri Chaitanya Polytechnic College</h2>
          <p>Check our Courses</p>
        </div>
        <section id="counts" className="counts">
            <div className="container" data-aos="fade-up">
              <div className="row no-gutters">
                <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
                  <div className="count-box text-center">
                   <img src='assets/img/CIVIL.png'   style={{width:'45%'}} className='mt-4' />
                    <span data-purecounter-start={0} data-purecounter-end={232} data-purecounter-duration={1} className="purecounter" />
                    <p><strong> Civil Engineering</strong></p>
                  <br />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
                  <div className="count-box text-center">
                  <img src='assets/img/ECE.png'   style={{width:'45%'}}/>
                    <span data-purecounter-start={0} data-purecounter-end={521} data-purecounter-duration={1} className="purecounter" />
                    <p><strong>Electronic Communication  Engineering</strong></p>
                  <br />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
                  <div className="count-box text-center">
                  <img src='assets/img/EEE.png'   style={{width:'45%'}} className='mt-3'/>
                    <span data-purecounter-start={0} data-purecounter-end={1463} data-purecounter-duration={1} className="purecounter" />
                    <p><strong>Electrical and Electronics  Engineering</strong></p>
                  <br />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
                  <div className="count-box text-center">
                  <img src='assets/img/MECH.png'   style={{width:'45%'}}/>
                    <span data-purecounter-start={0} data-purecounter-end={15} data-purecounter-duration={1} className="purecounter" />
                    <p><strong>Mechanical Engineering</strong></p>
                  <br />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="section-title">
                <h2>COURSE WISE INTAKE:</h2>
                <div className="mb-30 clearfix">
        <div className="table-responsive">
            <table className="content-table" border="0" width="100%" cellSpacing="1" cellPadding="10">
            <thead>
                <tr>
                <th className="in_gride"><strong>S.NO</strong></th>
                <th className="in_gride"><strong>COURSE</strong></th>
                <th className="in_gride"><strong>NUMBER OF INTAKE </strong></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                <td>1.</td>
                <td>Civil Engineering</td>
                <td>60 seats</td>
                </tr>
                <tr>
                <td>2.</td>
                <td>Electronic Communication  Engineering</td>
                <td>30 seats</td>
                </tr>
                <tr>
                <td>3.</td>
                <td>Electrical and Electronics  Engineering</td>
                <td>120 seats</td>
                </tr>
                <tr>
                <td>4.</td>
                <td>Mechanical Engineering</td>
                <td>180 seats</td>
                </tr>
            </tbody>
            </table>
        </div>
        </div>
            </div>
      </div>
    </section>{/* End Services Section */}</div>
  )
}
