
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

export default function Header() {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const handleMobileMenuToggle = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleNavLinkClick = () => {
    if (isMobileMenuOpen) {
      setMobileMenuOpen(false);
    }
  };

  const handleDropdownToggle = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <header id="header" className={`d-flex align-items-center header-scrolled ${isScrolled ? 'fixed' : ''}`}>
      <div className="container d-flex align-items-center">
          <div className="logo me-auto">
            
          </div>
          <div
            id="navbar"
            className={`navbar order-last order-lg-0 ${isMobileMenuOpen ? 'navbar-mobile' : ''}`}
          >
            
          <ul>
            <li>
              <NavLink
                exact
                to="/"
                className="nav-link scrollto active hover-3"
                onClick={handleNavLinkClick}
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/about"
                className="nav-link scrollto  hover-3"
                onClick={handleNavLinkClick}
              >
                About
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/Courses"
                className="nav-link scrollto  hover-3"
                onClick={handleNavLinkClick}
              >
                Courses
              </NavLink>
            </li>
            {/* <li className="dropdown">
            <a  className=" hover-3"  onClick={handleDropdownToggle}>
            <span style={{cursor:'pointer'}}>Courses</span> 
            <i className="bi bi-caret-down-fill" />
          </a>
          <ul className={`SMN_effect-90 dropdown ${isDropdownOpen ? 'dropdown-active' : ''}`}>
            <li>
              <NavLink
                to="/dropdown4"
                className="nav-link scrollto"
                onClick={handleNavLinkClick}
              >
                Civil Engineering
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/dropdown4"
                className="nav-link scrollto"
                onClick={handleNavLinkClick}
              >
                 Electronic Communication Engineering
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/dropdown4"
                className="nav-link scrollto"
                onClick={handleNavLinkClick}
              >
                 Electrical and Electronics Engineering
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/dropdown4"
                className="nav-link scrollto"
                onClick={handleNavLinkClick}
              >
                Mechanical Engineerings
              </NavLink>
            </li>
          </ul>
        </li> */}

            <li>
              <NavLink
                to="/Facilities"
                className="nav-link scrollto  hover-3"
                onClick={handleNavLinkClick}
              >
                Facilities
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/Recruites"
                className="nav-link scrollto  hover-3"
                onClick={handleNavLinkClick}
              >
                Our Recruiters
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/Feedback"
                className="nav-link scrollto  hover-3"
                onClick={handleNavLinkClick}
              >
                Feedback
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/contact"
                className="nav-link scrollto  hover-3"
                onClick={handleNavLinkClick}
              >
                Contact
              </NavLink>
            </li>
         
            <li>
              <NavLink
                to="https://docs.google.com/forms/d/e/1FAIpQLSfJc13RWduMhRl_jxSrWGIrasfI7G6svvW3_X3ZK-VxIp6kjQ/viewform?usp=sf_link"
                className="getstarted scrollto"
                onClick={handleNavLinkClick}
                target='_blank'
              >
                Grievance <i class="bi bi-chat-left-dots-fill"></i>
              </NavLink>
            </li>
          </ul>
            <i className={`bi ${isMobileMenuOpen ? 'bi-x' : 'bi-list'} mobile-nav-toggle`} onClick={handleMobileMenuToggle} />
          </div>
          <div className="logo me-auto">
            
          </div>
        </div>
        
      </header>
    </div>
  );
}
