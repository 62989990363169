import React,{ useEffect }  from 'react'

function FacilitiesaPage() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div>      <section id="Facilities" className="portfolio">
    <div className="container" data-aos="fade-up">
    <div className="section-title">
        <h2>Sri Chaitanya Polytechnic College</h2>
        <p>Library & Medical Center</p>
      </div>
      <div className="mb-30 clearfix">
        <div className="table-responsive">
            <table className="content-table" border="0" width="100%" cellSpacing="1" cellPadding="10">
            
            <tbody>
                <tr>
                <td>Library Timings</td>
                <td>8:30 AM - 6:00 PM</td>
            
                </tr>
                <tr>
                <td>Sunday & Holidays</td>
                <td>8:00 AM - 12:00 PM</td>
            
                </tr>
                <tr>
                <td>Seating Capacity</td>
                <td>100+</td>
            
                </tr>
            </tbody>
            </table>
        </div>
        </div>
        <br></br>
        <br></br>
        <br></br>
        <div className="section-title">
                <h2>Total Number of Books</h2>
                <div className="mb-30 clearfix">
        <div className="table-responsive">
            <table className="content-table" border="0" width="100%" cellSpacing="1" cellPadding="10">
            <thead>
                <tr>
                <th className="in_gride"><strong>S.I</strong></th>
                <th className="in_gride"><strong>Type</strong></th>
                <th className="in_gride"><strong>No.of titles</strong></th>
                <th className="in_gride"><strong>Volumes</strong></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                <td>1</td>
                <td>Referance Books</td>
                <td>100</td>
                <td>2000</td>
                </tr>
                <tr>
                <td>2</td>
                <td>Text Books</td>
                <td>100</td>
                <td>7000</td>
                </tr>
                <tr>
                <td></td>
                <td>Total</td>
                <td>200</td>
                <td>9000</td>
                </tr>
            </tbody>
            </table>
        </div>
        </div>
            </div>
            <br></br>
 
        <div className="section-title">
                <h2>National and International Journals(Discipline Wise)</h2>
                <div className="mb-30 clearfix">
        <div className="table-responsive">
            <table className="content-table" border="0" width="100%" cellSpacing="1" cellPadding="10">

            <tbody>
                <tr>
                <td>1</td>
                <td>Civil Engineering</td>
                <td>2</td>
            
                </tr>
                <tr>
                <td>2</td>
                <td>Electrical and Electronics Engineering</td>
                <td>4</td>
            
                </tr>
                <tr>
                <td>3</td>
                <td>Mechanical Engineering</td>
                <td>4</td>
            
                </tr>
                <tr>
                <td>4</td>
                <td>Electronics and Communication Engineering</td>
                <td>2</td>
            
                </tr>
                <tr>
                <td></td>
                <td>Total</td>
                <td>12</td>
            
                </tr>
            </tbody>
            </table>
        </div>
        </div>
            </div>
    
    <div className="section-title">
        <h2>Digital Library</h2>
      </div>
      <p><strong>Computers</strong>: The Library has 8 dedicated computers with multimedia and internet facility </p>
      <p><strong>Internet Facility</strong>: The Library has 50mbps internet Connectivity thriugh BSNL</p>
      <p><strong>E-matrial</strong>:</p>
      <p>Previous Question Papers</p>
      <p>e-books: 5,000+</p>
      <p>SBTET learing matrial (PPTs)</p>
      <p>Department Wise learing matrials</p>
      <br></br>
      <br></br>
      

      <div className="section-title">
                <h2>Librarian</h2>
                <div className="mb-30 clearfix">
        <div className="table-responsive">
            <table className="content-table" border="0" width="100%" cellSpacing="1" cellPadding="10">
          
            <tbody>
                <tr>
              
                <td>K.SrinivasaRao B.com, L.I.SC</td>
                <td>Librarian</td>
           
                </tr>

                <tr>
             
                <td>M.Bhaskara rao BA, M.A, L.I.SC</td>
                <td>Asst.Librarian</td>
        
                </tr>

              
            </tbody>
            </table>
        </div>
        </div>
            </div>


  



 
    
    </div>
  </section></div>
  )
}

export default FacilitiesaPage