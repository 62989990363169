import React from 'react'

export default function About() {
  return (
    <div>          <section id="about" className="about">
    <div className="container" data-aos="fade-up">
      <div className="section-title">
        <h2>About</h2>
        <p>WELCOME TO <strong>Sri Chaitanya Polytechnic College</strong></p>
      </div>
      <div className="row content">
        <div className="col-lg-12">
          <p>
          Established in 2009, Sri Chaitanya Polytechnic College offers diploma courses in Civil Engineering (with 60 seats), Electronic Communication (with 30 seats), Electrical and Electronics (with 120 seats), and Mechanical Engineering (with 180 seats). The institution's primary goal is to provide academic excellence to all segments of society, emphasizing disciplined integrated personality development, research, and management skills to tackle global challenges.
          </p>
      
        </div>
        {/* <div className="col-lg-12 pt-4 pt-lg-0">
          <p>
           
        The Ramakrishna Industriual Training Institute (ITI) was established in 2013 .
        with three trades FITTER electriian & welder. 
        Our Chairman has started this ITI to provide employment to economically poor background class students, who due to various reasons, are unable to pursue 
        further studies after SSC.
        // <h3 style={{fontWeight:'400',fontSize:'34px',color:'#123a6d'}}>WELCOME TO <strong>Ramakrishna ITI </strong></h3>
          </p>
       
        </div> */}
      </div>
    </div>
  </section>
    {/* ======= Counts Section ======= */}
   {/* ======= Counts Section ======= */}
    <div style={{ paddingTop: '50px' }}>
    <section id="counts" className="counts">
            <div className="container" data-aos="fade-up">
              <div className="row no-gutters">
                <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
                  <div className="count-box text-center">
                   <img src='assets/img/CIVIL.png'   style={{width:'45%'}} className='mt-4' />
                    <span data-purecounter-start={0} data-purecounter-end={232} data-purecounter-duration={1} className="purecounter" />
                    <p><strong> Civil Engineering</strong></p>
                  <br />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
                  <div className="count-box text-center">
                  <img src='assets/img/ECE.png'   style={{width:'45%'}}/>
                    <span data-purecounter-start={0} data-purecounter-end={521} data-purecounter-duration={1} className="purecounter" />
                    <p><strong>Electronic Communication  Engineering</strong></p>
                  <br />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
                  <div className="count-box text-center">
                  <img src='assets/img/EEE.png'   style={{width:'45%'}} className='mt-3'/>
                    <span data-purecounter-start={0} data-purecounter-end={1463} data-purecounter-duration={1} className="purecounter" />
                    <p><strong>Electrical and Electronics  Engineering</strong></p>
                  <br />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
                  <div className="count-box text-center">
                  <img src='assets/img/MECH.png'   style={{width:'45%'}}/>
                    <span data-purecounter-start={0} data-purecounter-end={15} data-purecounter-duration={1} className="purecounter" />
                    <p><strong>Mechanical Engineering</strong></p>
                  <br />
                  </div>
                </div>
              </div>
            </div>
          </section>{/* End Counts Section */}</div>
          
          {/* End Counts Section */}
                 {/* ======= Why Us Section ======= */}
                 <section id="why-us" className="why-us section-bg">
            <div className="container-fluid" data-aos="fade-up">
              <div className="row">
                <div className="col-lg-5 align-items-stretch video-box" style={{backgroundImage: 'url("assets/img/clg.jpg")'}} data-aos="zoom-in" data-aos-delay={100}>
                 
                </div>
                <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch">
                  <div className="content">
                    {/* <h3>WELCOME TO <strong>Ramakrishna ITI </strong></h3> */}
                    <p>
                    Sri Chaitanya Polytechnic College, established in 2009, stands as a beacon of academic excellence in Andhra Pradesh. With a commitment to providing quality education to all sections of society, the institution offers diploma courses in Civil Engineering, Electronic Communication, Electrical and Electronics, and Mechanical Engineering. Equipped with modern facilities and a dedicated faculty, the college strives to nurture disciplined, integrated personality development among its students.
                    </p>
                    <br />
                    <p>
                    Endorsed by the All India Council for Technical Education (AICTE) and affiliated with the State Board of Technical Education and Training, Andhra Pradesh (SBTET-AP), Sri Chaitanya Polytechnic College operates from its serene campus in Panukuvalasa, Pachipenta. Located in the picturesque Manyam district, the college embraces a holistic approach to education, emphasizing not only academic prowess but also research and management skills essential for addressing global challenges.
                    </p>
                    <br />
                    <p>
                    At Sri Chaitanya Polytechnic College, we believe in empowering our students with the knowledge and skills necessary to thrive in a rapidly evolving world. With a focus on fostering innovation and creativity, we aim to produce competent professionals who can contribute meaningfully to society. Our Polycet code, SCPC, is a symbol of our dedication to providing accessible and inclusive education to aspiring engineers from diverse backgrounds.






                    </p>
                    <br />
                    <br />
                    
                  </div>
                  
                </div>
              </div>
            </div>
          </section>{/* End Why Us Section */}</div>
  )
}
